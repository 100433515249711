import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WhyUsCard from '../WhyUsCard/WhyUsCard';
import styles from './ValueCard.module.scss';

function ValueCard({ className, ...props }) {
    return (
        <WhyUsCard
            title="Value"
            icon="coins"
            className={classNames(styles.valueCard, className)}
            {...props}
        >
            <p>
                We promise to make sure you enjoy the highest possible value for
                your budget.
            </p>
        </WhyUsCard>
    );
}

ValueCard.propTypes = {
    className: PropTypes.string,
};

export default ValueCard;
