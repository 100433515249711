import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'next/link';
import PhoneNumber from '../../common/PhoneNumber/PhoneNumber';
import WhyUsCard from '../WhyUsCard/WhyUsCard';
import styles from './BuiltForYouCard.module.scss';

function BuiltForYouCard({ className, ...props }) {
    return (
        <WhyUsCard
            title="Built For You"
            icon="builtForYou"
            className={classNames(styles.builtForYouCard, className)}
            {...props}
        >
            <p>
                We have over 10,000 available tours and something to suit
                everyone or build your own with our Tailor Made team.
            </p>
            <Link href="/contact-us">
                <a alt="The Holiday People Team">
                    Contact Our Tailor Made Team
                </a>
            </Link>
            <p className={styles.callOurExperts}>Call our experts on</p>
            <PhoneNumber className={styles.phoneNumber} />
        </WhyUsCard>
    );
}

BuiltForYouCard.propTypes = {
    className: PropTypes.string,
};

export default BuiltForYouCard;
