import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    contactEmail,
    displayTelephoneNumber,
    telephoneNumber,
} from '../../../utils/constants';
import Button from '../../common/Button/Button/Button';
import styles from './ContactActions.module.scss';

function ContactActions({ className, ...props }) {
    return (
        <div
            className={classNames(styles.contactActions, className)}
            {...props}
        >
            <Button
                as="a"
                href={`tel:${telephoneNumber}`}
                className={styles.action}
            >
                Call us on {displayTelephoneNumber} &gt;&gt;
            </Button>
            <Button
                as="a"
                href={`mailto:${contactEmail}`}
                className={styles.action}
            >
                Email us &gt;&gt;
            </Button>
        </div>
    );
}

ContactActions.propTypes = {
    className: PropTypes.string,
};

export default ContactActions;
