import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './WhyUsCard.module.scss';
import Icon from '../../common/Icon/Icon';

function WhyUsCard({ title, icon, children, className, ...props }) {
    return (
        <li className={classNames(styles.whyUsCard, className)} {...props}>
            <div className={styles.icon}>
                <Icon icon={icon} color="ffffff" width={20} height={20} />
            </div>
            <h3>{title}</h3>
            {children}
        </li>
    );
}

WhyUsCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default WhyUsCard;
