import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WhyUsCard from '../WhyUsCard/WhyUsCard';
import Icon from '../../common/Icon/Icon';
import styles from './FlexibleCard.module.scss';

function FlexibleCard({ className, ...props }) {
    return (
        <WhyUsCard
            title="Flexible"
            icon="flexible"
            className={classNames(styles.flexibleCard, className)}
            {...props}
        >
            <ul>
                <li>
                    <Icon
                        className={styles.checkIcon}
                        icon="check"
                        width={20}
                        height={20}
                    />
                    Free Date Changes
                </li>
                <li>
                    <Icon
                        className={styles.checkIcon}
                        icon="check"
                        width={20}
                        height={20}
                    />
                    Monthly Payment Plans
                </li>
                <li>
                    <Icon
                        className={styles.checkIcon}
                        icon="check"
                        width={20}
                        height={20}
                    />
                    Low Deposits
                </li>
                <li>
                    <Icon
                        className={styles.checkIcon}
                        icon="check"
                        width={20}
                        height={20}
                    />
                    Build Your Own
                </li>
            </ul>
        </WhyUsCard>
    );
}

FlexibleCard.propTypes = {
    className: PropTypes.string,
};

export default FlexibleCard;
