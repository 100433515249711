import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ContactOurTeam.module.scss';
import OpeningHours from './OpeningHours/OpeningHours';
import ContactActions from './ContactActions/ContactActions';

function ContactOurTeam({ className, ...props }) {
    return (
        <section
            className={classNames(styles.contactOurTeam, className)}
            {...props}
        >
            <div className={styles.inner}>
                <h2>Contact our team</h2>
                <p>
                    We believe in the value of people. Our travel experts have
                    extensive experience and are here to help with any enquiry.
                    Hope to hear from you soon!
                </p>
                <ContactActions className={styles.actions} />
                <OpeningHours className={styles.hours} />
            </div>
        </section>
    );
}

ContactOurTeam.propTypes = {
    className: PropTypes.string,
};

export default ContactOurTeam;
