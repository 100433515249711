import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WhyUsCard from '../WhyUsCard/WhyUsCard';
import atolLogo from '../../../../public/images/atol/atol-logo.svg';
import abtaLogo from '../../../../public/images/abta/abta-logo-p8142.svg';
import styles from './TrustCard.module.scss';
import Image from 'next/image';

function TrustCard({ className, ...props }) {
    return (
        <WhyUsCard
            title="Trust"
            icon="shield"
            className={classNames(styles.trustCard, className)}
            {...props}
        >
            <p>
                Your money is protected by our Trust account and Client account.
            </p>
            <div className={styles.logos}>
                <Image
                    src={atolLogo}
                    alt="ATOL member No. 11740"
                    loading="lazy"
                    width={80}
                    height={80}
                />
                <Image
                    src={abtaLogo}
                    alt="ABTA member No. P8142"
                    loading="lazy"
                    width={90}
                    height={50}
                />
                <Image
                    src="/images/atta/ATTA-member.png"
                    alt="ATTA member"
                    loading="lazy"
                    width={110}
                    height={49}
                />
                <Image
                    src="/images/atas/atas-logo.png"
                    alt="ATAS member"
                    loading="lazy"
                    width={110}
                    height={73}
                />
            </div>
        </WhyUsCard>
    );
}

TrustCard.propTypes = {
    className: PropTypes.string,
};

export default TrustCard;
