import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'next/image';
import WhyUsCard from '../WhyUsCard/WhyUsCard';
import styles from './TravelExpertCard.module.scss';

function TravelExpertCard({ className, ...props }) {
    return (
        <WhyUsCard
            title="Experts"
            icon="team"
            className={classNames(styles.travelExpertCard, className)}
            {...props}
        >
            <p>
                Our team are all experienced travellers, expedition leaders and
                instructors.
            </p>
            <Image
                className={styles.expert}
                src="/images/travel-expert-toby.jpg"
                height={150}
                width={150}
                alt="Travel Expert"
                loading="lazy"
            />
        </WhyUsCard>
    );
}

TravelExpertCard.propTypes = {
    className: PropTypes.string,
};

export default TravelExpertCard;
