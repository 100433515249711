import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Hero.module.scss';
import Image from 'next/image';

const cosmicImgxLoader = ({ src, width, quality }) => {
    return `${src}?h=400&w=${width}&fit=crop&crop=edges`;
};

function Hero({
    heading,
    subheading,
    hideHeaderBackground = false,
    imgSrc,
    className,
    ...props
}) {
    return (
        <section className={classNames(styles.hero, className)} {...props}>
            <Image
                loader={cosmicImgxLoader}
                src={imgSrc}
                layout="fill"
                objectFit="cover"
                alt=""
            />
            <div
                className={classNames(styles.headings, {
                    [styles.hide]: hideHeaderBackground,
                })}
            >
                <h1>{heading}</h1>
                {!!subheading && <h2>{subheading}</h2>}
            </div>
        </section>
    );
}

Hero.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    hideHeaderBackground: PropTypes.bool,
    className: PropTypes.string,
};

export default Hero;
