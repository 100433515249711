import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Button.module.scss';

function Button({
    as: T = 'button',
    children,
    className,
    disabled = false,
    ...props
}) {
    return (
        <T
            className={classNames(styles.button, className)}
            disabled={disabled}
            {...props}
        >
            {children}
        </T>
    );
}

Button.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

export default Button;
