import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TrustCard from './TrustCard/TrustCard';
import TravelExpertCard from './TravelExpertCard/TravelExpertCard';
import BuiltForYouCard from './BuiltForYouCard/BuiltForYouCard';
import FlexibleCard from './FlexibleCard/FlexibleCard';
import ValueCard from './ValueCard/ValueCard';
import styles from './WhyTheHolidayPeople.module.scss';

function WhyTheHolidayPeople({ className, ...props }) {
    return (
        <section
            className={classNames(styles.whyTheHolidayPeople, className)}
            {...props}
        >
            <div className={styles.wrapper}>
                <h2 className={styles.title}>Why The Holiday People?</h2>
                <ul className={styles.board}>
                    <TrustCard
                        className={classNames(styles.trust, styles.card)}
                    />
                    <TravelExpertCard
                        className={classNames(styles.experts, styles.card)}
                    />
                    <BuiltForYouCard
                        className={classNames(styles.built, styles.card)}
                    />
                    <FlexibleCard
                        className={classNames(styles.flex, styles.card)}
                    />
                    <ValueCard
                        className={classNames(styles.value, styles.card)}
                    />
                </ul>
            </div>
        </section>
    );
}

WhyTheHolidayPeople.propTypes = {
    className: PropTypes.string,
};

export default WhyTheHolidayPeople;
