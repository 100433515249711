import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { openingHours } from '../../../utils/constants';
import styles from './OpeningHours.module.scss';

function OpeningHours({ className, ...props }) {
    return (
        <div className={classNames(styles.openingHours, className)} {...props}>
            <h3>Opening Hours</h3>

            <ul className={styles.openings}>
                {openingHours.map(({ dayOfWeek, timeRange }) => (
                    <li className={styles.opening} key={dayOfWeek}>
                        <span className={styles.day}>{dayOfWeek}</span>
                        <span className={styles.time}>{timeRange}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

OpeningHours.propTypes = {
    className: PropTypes.string,
};

export default OpeningHours;
